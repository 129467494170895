import "core-js/modules/es.function.name.js";
import { createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue";
var _hoisted_1 = {
  class: "bg-gray-700 bg-opacity-50 border-b border-gray-500 py-2 text-2xl text-center text-white",
  ref: "h1"
};
var _hoisted_2 = {
  class: "container mx-auto"
};
var _hoisted_3 = {
  key: 0,
  class: "flex justify-center flex-wrap md:grid md:grid-cols-2 md:gap-2 xl:grid-cols-3 xl:gap-4 items-baseline"
};
var _hoisted_4 = {
  class: "text-xl"
};
var _hoisted_5 = {
  class: "text-green-our my-4 text-lg font-bold"
};
var _hoisted_6 = {
  class: "flex justify-between items-baseline px-2 flex-wrap"
};
var _hoisted_7 = {
  key: 1,
  class: "mt-24 text-2xl text-center"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createBlock(_Fragment, null, [_createVNode("h1", _hoisted_1, "Achievements", 512), _createVNode("div", _hoisted_2, [$options.getAchievementsStructure.length > 0 ? (_openBlock(), _createBlock("section", _hoisted_3, [(_openBlock(true), _createBlock(_Fragment, null, _renderList($options.getAchievementsStructure, function (achievementStructure, idx) {
    return _openBlock(), _createBlock("div", {
      key: idx,
      class: "w-11/12 px-2 bg-gradient-to-b from-gray-dark to-black shadow-md border-b-4 border-solid border-orange-our mt-4 py-4"
    }, [_createVNode("h3", _hoisted_4, _toDisplayString(achievementStructure.name), 1), achievementStructure.subCategory.length > 0 ? (_openBlock(true), _createBlock(_Fragment, {
      key: 0
    }, _renderList(achievementStructure.subCategory, function (subCategor, index) {
      return _openBlock(), _createBlock("div", {
        key: index,
        class: "px-2"
      }, [_createVNode("h4", _hoisted_5, _toDisplayString(subCategor.name), 1), _createVNode("div", _hoisted_6, [(_openBlock(true), _createBlock(_Fragment, null, _renderList(subCategor.achievements, function (subCat, id) {
        return _openBlock(), _createBlock("figure", {
          class: "relative cursor-pointer mt-5",
          key: id,
          onClick: function onClick($event) {
            return $options.zobrazModal($options.getAchievement(subCat));
          }
        }, [_createVNode("img", {
          src: _ctx.$store.getters.getAchievement(subCat).icon,
          class: $options.getAchievementSize($options.getAchievement(subCat).size)
        }, null, 10, ["src"]), !$options.getUserAchievementCount(subCat) ? (_openBlock(), _createBlock("figcaption", {
          key: 0,
          class: [$options.getAchievementBorderColor($options.getAchievement(subCat).class), "absolute -top-3 -right-3 px-3 py-0 leading-5 text-white rounded-sm border border-dashed"]
        }, "0", 2)) : (_openBlock(), _createBlock("figcaption", {
          key: 1,
          class: ["absolute -top-3 -right-3 px-3 py-0 leading-5 text-white rounded-sm", $options.getAchievementColor($options.getAchievement(subCat).class)]
        }, _toDisplayString($options.getUserAchievementCount(subCat)), 3))], 8, ["onClick"]);
      }), 128))])]);
    }), 128)) : _createCommentVNode("", true)]);
  }), 128))])) : (_openBlock(), _createBlock("section", _hoisted_7, " No achievements "))])], 64);
}