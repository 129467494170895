export default {
  created: function created() {
    //-------pozadie stranky-------
    document.getElementsByTagName('body')[0].classList.add('bgImageBlue');
  },
  computed: {
    getAchievementsStructure: function getAchievementsStructure() {
      return this.$store.getters.getAchievementsStructure;
    }
  },
  methods: {
    getAchievement: function getAchievement(id) {
      return this.$store.getters.getAchievement(id);
    },
    getAchievementSize: function getAchievementSize(size) {
      parseInt(size);

      if (size == 1) {
        return {
          'w-24': true,
          'h-24': true
        };
      } else if (size == 2) {
        return {
          'w-20': true,
          'h-20': true
        };
      } else if (size == 3) {
        return {
          'w-16': true,
          'h-16': true
        };
      } else {
        return {
          'w-12': true,
          'h-12': true
        };
      }
    },
    getAchievementColor: function getAchievementColor(color) {
      if (color == "orange") {
        return {
          'bg-orange-our': true
        };
      } else {
        return {
          'bg-green-our': true
        };
      }
    },
    getAchievementBorderColor: function getAchievementBorderColor(color) {
      if (color == "orange") {
        return {
          'border-orange-our': true
        };
      } else {
        return {
          'border-green-our': true
        };
      }
    },
    zobrazModal: function zobrazModal(achievObj) {
      this.$store.commit('setUpperModal', {
        visible: true,
        whatToShow: 'ZoomInAchieve',
        data: {
          achievObj: achievObj
        }
      });
    },
    getUserAchievementCount: function getUserAchievementCount(idAchievement) {
      return this.$store.getters.getUserAchievementCount(idAchievement);
    }
  }
};